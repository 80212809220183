import React from 'react';
import classes from './Layout.module.css'
import Header from "./Header/Header";
import {Outlet} from "react-router-dom";
import ActionSheet from "../../../components/ActionSheet/ActionSheet";
import SideMenu from "./SideMenu/SideMenu";
import {isBrowser} from "react-device-detect";
import useAuth from "../../../Hooks/useAuth";

const Layout = () => {

    const {auth, setAuth} = useAuth();

    return (
       <>
           <div className={`${classes.container} row jc-center ai-center`}>

               {((isBrowser) && (auth?.isLogin)) && <SideMenu/>}

               <div className={`${classes.content} height-100 position-relative`}>
                   <Header/>
                   <div className={`height-91`}>
                       <Outlet/>
                   </div>
                   <ActionSheet/>
               </div>



           </div>
       </>
    );
};

export default Layout;
