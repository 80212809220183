import {getCurrencies} from "../api/exchange";

export const loadConfig = async (global, setGlobal) => {

    let newGlobal = {...global}

    newGlobal = {
        ...newGlobal,
        isLoading: true,
    }

    try {

        const getCurrenciesFunc = await getCurrencies()

        newGlobal = {
            ...newGlobal,
            isLoading: false,
            accessDenied: false,
            hasError: false,
            currencies: getCurrenciesFunc?.data?.data
        }

    } catch (e) {

        if (e?.response?.status === 503) {
            newGlobal = {
                ...newGlobal,
                isLoading: false,
                accessDenied: true,
            }
        } else {
            newGlobal = {
                ...newGlobal,
                isLoading: false,
                hasError: true,
            }
        }


    }

    setGlobal(newGlobal)

}